import {_, React} from 'components';
import {link} from './helpers';
import {Link} from 'react-router-dom';

// istanbul ignore file

export default {
  key: 'visit-a-showroom',
  title: 'Visit a Showroom',
  sections: [
    {
      layout: 'text',
      heightAuto: true,
      text: {
        items: [
          {className: 'sub-heading', text: 'Visit a Showroom'},
          `When you choose to work with Space Theory, you are ordering a unique, made-to-order product based on decades of functional design expertise. Each of our showrooms, from Seattle to New York, Los Angeles, and soon the Bay Area, are designed for you to experience the Space Theory system up close and in person.`
        ]
      }
    },
    {
      layout: 'custom',
      heightAuto: true,
      render: () => {
        var locations = [
          {
            city: 'Mill Valley',
            state: 'California',
            key: 'mill-valley'
          },
          {
            city: 'New York City',
            state: 'New York',
            key: 'new-york'
          },
          {
            city: 'Los Angeles',
            state: 'California',
            key: 'los-angeles'
          },
          {
            city: 'Seattle',
            state: 'Washington',
            key: 'seattle'
          }
        ];

        return (
          <div className='showrooms'>
            <div className='tiles-container'>
              {_.map(locations, (location, s) => {
                var imageLink = link(`showrooms/${location.key}.jpg`);

                return (
                  <Link className='tile' to={`/page/${location.key}`} key={s}>
                    <div className='tile-title'>
                      {/* {location.openingSoon && <div className='tile-title-coming-soon'>Opening Soon</div>} */}
                      <div className='tile-title-city'>{location.city}</div>
                      <div className='tile-title-state'>{location.state}</div>
                    </div>
                    <div className='tile-image-container'>
                      <div className='tile-image' style={{backgroundImage: `url(${imageLink})`}}></div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        );
      }
    }
  ]
};
