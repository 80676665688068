import {_, React, Component} from 'components';
import {Form as SemanticForm} from 'semantic-ui-react';

import TextInput from './inputs/text/text-input';
import DropdownInput from './inputs/dropdown/dropdown-input';
import RadioInput from './inputs/radio/radio-input';
import HiddenInput from './inputs/hidden/hidden-input';
import SubmitButton from './submit-button/submit-button';

import './form.scss';

class Form extends Component{
  static defaultProps = {
    useSubmitInput: false,
  }

  constructor(props) {
    super(props);

    this.bind(['handleSubmit', 'setRecaptchaToken']);

    this.state = {
      isSubmitting: false,
      recaptchaToken: '',
    };
  }

  setRecaptchaToken(token) {
    this.setState({recaptchaToken: token});

    this.setTimeout(() => document.getElementById('space-theory-form').submit(), 50);

    this.setTimeout(() => this.setState({isSubmitting: false}), 1000);
  }

  handleSubmit({event}) {
    event.preventDefault();

    this.setState({isSubmitting: true});

    var {grecaptcha} = window;
    var {setRecaptchaToken} = this;

    grecaptcha.ready(function() {
      grecaptcha.execute('6Lc15rMqAAAAAKTjnGw_6CFlWaQ8NBtrqvaAYuIe', {action: 'submit'}).then(function(token) {
       setRecaptchaToken(token);
      });
    });
  }

  render() {
    return (
      <SemanticForm
        {..._.pick(this.props, ['action', 'method', 'encType'])}
        onSubmit={(event) => this.handleSubmit({event})}
        id='space-theory-form'
      >
        {this.props.children}
        {this.props.useSubmitInput && (
          <SubmitButton
            isSubmitting={this.state.isSubmitting}
            submitText={this.props.submitText}
            isSubmittingText={this.props.isSubmittingText}
          />
        )}
        <Form.HiddenInput name="g-recaptcha-response" value={this.state.recaptchaToken} />
      </SemanticForm>
    );
  }
}

Form.TextInput = TextInput;
Form.DropdownInput = DropdownInput;
Form.RadioInput = RadioInput;
Form.HiddenInput = HiddenInput;
Form.SubmitButton = SubmitButton;

export default Form;
