import {_, React, Component} from 'components';
import Form from 'lib/form/form';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import './inquiry-form.scss';

var locationOptions = [
  {value: '2', title: 'Northern California'},
  {value: '1', title: 'Southern California'},
  {value: '3', title: 'New York, New Jersey, or Connecticut'},
  {value: '4', title: 'Washington, Oregon, or Idaho'},
  {value: '5', title: 'Montana, Colorado, Utah, or Wyoming'},
  {value: '6', title: 'Texas'},
  {value: '7', title: 'Mexico and Canada'},
  {value: '8', title: 'Somewhere else'}
];

export default class InquiryForm extends Component {
  constructor(props) {
    super(props);

    this.changeHandlers = {};

    this.queryParams = queryString.parse(this.props.location.search, {ignoreQueryPrefix: true});

    var contactReason = 'project';

    if (this.queryParams.contactReason) {
      contactReason = this.queryParams.contactReason;
    }
    else if (props.contactReason) {
      contactReason = props.contactReason;
    }

    this.state = {
      name: '',
      email: '',
      phone: '',
      contactReason,
      contactMethod: '2',
      notes: '',
      // state: `${stateOptions[0].value}`,
      location: `${locationOptions[0].value}`,
      type: '1'
    };

    this.contactReasonNameMap = {
      project: {
        name: '1',
        email: '349',
        phone: '350',
        contactMethod: '310',
        notes: '7653',
        // state: '308',
        location: '10221',
        type: '3'
      },
      subscribe: {
        name: '5992',
        email: '5995',
        phone: '8545',
        type: '8670'
      }
    };

    this.typeOptionsMap = {
      project: [
        {value: '1', title: 'Homeowner'},
        {value: '2', title: 'Architect'},
        {value: '5', title: 'Interior Designer'},
        {value: '3', title: 'Developer'},
        {value: '0', title: 'Other'},
      ],
      subscribe: [
        {value: '1', title: 'Homeowner'},
        {value: '2', title: 'Architect'},
        {value: '3', title: 'Interior Designer'},
        {value: '4', title: 'Developer'},
        {value: '0', title: 'Other'},
      ]
    };

    this.sourceOptionsMap = {
      project: [
        {value: '7', title: ''},
        {value: '9', title: 'Remodelista Ad'},
        {value: '11', title: 'Inquiry Form'}
      ],
      subscribe: [
        //TODO not tracked
      ]
    }
  }

  componentDidMount() {
    var googleAnalyticsScript = document.createElement("script");
    var googleAnalyticsNoScript = document.createElement("noscript");

    //HINT unique Google Analytics tag for contact form
    googleAnalyticsScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TBGRXXWJ');`;

    document.head.insertBefore(googleAnalyticsScript, document.head.firstChild);

    var googleAnalyticsiframe = document.createElement("iframe");
    googleAnalyticsiframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-TBGRXXWJ";
    googleAnalyticsiframe.height = 0;
    googleAnalyticsiframe.width = 0;
    googleAnalyticsiframe.style = "display:none;visibility:hidden";

    googleAnalyticsNoScript.appendChild(googleAnalyticsiframe);
    document.body.insertBefore(googleAnalyticsNoScript, document.body.firstChild);
  }

  handleChange({name, value}) {
    Cookies.set('hasUsedInquiryForm', 1, {expires: 30});

    this.setState((oldState) => {
      var newState = {[name]: value};

      if (name === 'contactReason') {
        var oldTypeTitle = _.find(this.typeOptionsMap[oldState.contactReason], {value: oldState.type}).title;

        newState.type = _.find(this.typeOptionsMap[value], {title: oldTypeTitle}).value;
      }

      return newState;
    });
  }

  inputPropsFor({name}) {
    if (!this.changeHandlers[name]) this.changeHandlers[name] = ({value}) => this.handleChange({name, value});

    return {
      on: {change: this.changeHandlers[name]},
      value: this.state[name],
      name: this.contactReasonNameMap[this.state.contactReason][name] || name
    };
  }

  determineSourceFor({contactReason}) {
    var sourceOption = this.sourceOptionsMap[contactReason][0];

    if (contactReason === 'project') {
      var isRemodelistaVisitor = Cookies.get('isRemodelistaVisitor');

      var inquiryPopupCookie = Cookies.getJSON('st_inquiry_popup');
      var userAction = _.get(inquiryPopupCookie, 'userAction');
      var userClickedContactUsButton = userAction === 'buttonClick';

      if (userClickedContactUsButton) {
        sourceOption = _.find(this.sourceOptionsMap.project, {title: 'Inquiry Form'});
      }
      else if (isRemodelistaVisitor) {
        sourceOption = _.find(this.sourceOptionsMap.project, {title: 'Remodelista Ad'});
      }
    }

    return sourceOption.value;
  }

  render() {
    return (
      <div className='inquiry-form theme-light'>
        {this.queryParams.showThankYouMessage ? (
          <div className='thank-you-message'>
            Thank you for your interest in Space Theory.
            <br /><br />
            If you submitted a project inquiry, a member of our team will contact you within two business days.
          </div>
        ) : (
          <div className='form-and-prompt-wrapper'>
            <div className='inquiry-form-prompt'>
              <div className='prompt-text'>
                If you have an active project or would like additional information, please fill out our inquiry form below so a member of our team can quickly contact you.
                <br /><br />
                If you are already discussing your project with Henrybuilt and would like to learn more about Space Theory, please contact your Henrybuilt representative to assist you.
              </div>
            </div>
            <div className='form-wrapper' ref={(ref) => {
              if (ref) {
                ref.querySelector('input[name="1"]').setAttribute('required', true);
                ref.querySelector('input[name="349"]').setAttribute('required', true);
                ref.querySelector('input[name="350"]').setAttribute('required', true);
                ref.querySelector('input[name="8865"]').setAttribute('required', true);
              }
            }}>
              <Form action='https://www.weflowapp.com/w2e/post' method='post' useSubmitInput handleSubmit={this.handleSubmit}>
              {/* <Form action='https://weflow-php-staging-1.herokuapp.com/w2e/post' method='post' useSubmitInput handleSubmit={this.handleSubmit}> */}
                <div>
                  <Form.TextInput {...this.inputPropsFor({name: 'name'})} label={'Name'}/>
                  <Form.DropdownInput
                    label={'I am a'}
                    optionsKey={this.state.contactReason}
                    {...this.inputPropsFor({name: 'type'})}
                    options={_.map(this.typeOptionsMap[this.state.contactReason], option => ({...option, key: option.title}))}/>
                </div>
                <div>
                  <Form.TextInput {...this.inputPropsFor({name: 'email'})} label={'Email'}/>
                  <Form.TextInput {...this.inputPropsFor({name: 'phone'})} label={'Phone'}/>
                </div>
                {this.state.contactReason === 'project' ? (
                  <>
                    <br />
                    <Form.RadioInput {...this.inputPropsFor({name: 'contactMethod'})} options={[
                      {title: 'I\'d like to be contacted via email', value: '2'},
                      {title: 'I\'d like to be contacted via phone', value: '1'}
                    ]}/>
                    <br />
                    <div>
                      <Form.DropdownInput
                        label={'Where is your project located?'}
                        optionsKey={this.state.location}
                        isSearchable={false}
                        {...this.inputPropsFor({name: 'location'})}
                        options={_.map(locationOptions, option => ({...option, key: option.title}))}/>
                      <Form.TextInput
                        {...this.inputPropsFor({name: 'notes'})}
                        label='Is there anything you would like to share with us about your project?'/>
                    </div>
                    <Form.HiddenInput name='8865' value={1}/>
                    <Form.HiddenInput name='10707' value={this.determineSourceFor({contactReason: 'project'})}/>
                    <Form.HiddenInput name='w2e_id' value={107}/>
                    <Form.HiddenInput name='section_id' value={1}/>
                    <Form.HiddenInput name='grp_id' value={1}/>
                    <Form.HiddenInput name='14585' value={1}/>
                  </>
                ) : (
                  <>
                    <br />
                    <Form.HiddenInput name='6328' value={3}/>
                    <Form.HiddenInput name='w2e_id' value={108}/>
                    <Form.HiddenInput name='section_id' value={646}/>
                    <Form.HiddenInput name='grp_id' value={1}/>
                  </>
                )}
                <br /><br />
              </Form>
            </div>
          </div>
        )}
      </div>
    );
  }
}
