import {_, Component, React, PropTypes} from 'components'; //eslint-disable-line
import './footer.scss';

export default class Footer extends Component {
  render() {
    return (
      <div className='footer' >
        <div className="careers-link"><a href="/page/careers">Careers</a></div>
        <div className="private-policy-link"><a target="_blank" rel='noopener noreferrer' href="https://henrybuilt-cdn.s3-us-west-2.amazonaws.com/files/legal/henrybuilt/privacy.pdf">Privacy Policy</a></div>
        <div className='cky-banner-element tracking-options'>Change Cookie Preferences</div>
        {/* <div className="sf-credit">Site by <a href="https://www.symbolicframeworks.com" target="_blank" rel="noopener noreferrer">Symbolic Frameworks</a></div> */}
      </div>
    );
  }
}
