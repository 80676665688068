import {React} from 'components';
import {link} from './helpers';

// istanbul ignore file

export default {
  key: 'mill-valley',
  title: 'Mill Valley',
  sections: [
    {
      layout: 'textLeftMediaRight',
      text: {
        items: [
          'Opening 2025',
          'Space Theory’s Mill Valley showroom is opening this February, but our team is available to host visitors by appointment in the meantime.',
          'If you are interested in discussing a project, please get in touch. We would be happy to meet with you and share more about what a Space Theory kitchen might look like for your home.',
          {render: () => (
            <a className='phone' href='tel:4158020901'>415.802.0901</a>
          )},
          {render: () => (
            <a className='email' href='mailto:bayarea@spacetheory.com'>bayarea@spacetheory.com</a>
          )}
        ]
      },
      media: [{orientation: 'square', src: link('mill-valley/1.jpg')}],
    }
  ],
  structuredData: {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Space Theory",
    "description": "An intelligent kitchen system from the founders of Henrybuilt. Made to order in Seattle for homes throughout North America.",
    "url": "https://www.spacetheory.com/",
    "logo": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
    "image": "https://spacetheory-public.s3.amazonaws.com/images/full-logo.jpg",
    "hasMap": "",
    "branchOf": {
      "@type": "Organization",
      "name": "Space Theory",
      "url": "https://www.spacetheory.com"
    },
    "location": [
      {
        "@type": "LocalBusiness",
        "@id": "https://www.spacetheory.com/page/mill-valley",
        "name": "Mill Valley Location",
        "url": "https://www.spacetheory.com/page/mill-valley",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "360 Miller Ave",
          "addressLocality": "Mill Valley",
          "addressRegion": "CA",
          "postalCode": "94941",
          "addressCountry": "United States"
        },
        "telephone": "+1-415-802-0901",
        "openingHours": "",
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": "37.89772682612715",
          "longitude": "-122.53778325218984"
        }
      }
    ],
    "sameAs": [
      "https://www.facebook.com/spacetheorykitchens/",
      "https://www.instagram.com/space.theory/"
    ]
  }
};
